import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import PropTypes from "prop-types"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import Body from "./doctor/Body"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import TopSection from "./doctor/TopSection"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}

var showdown = require("showdown")
const converter = new showdown.Converter()

const SingleDoctor = props => {
  var pageContext = props.pageContext
  var location = props.location
  var data = props.data
  var post
  var language

  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allDoctorsJson.nodes[0]
        break
      case "es":
        post = data.allSpanishUniquePagesJson.nodes[0]
        break
      default:
        post = data.allDoctorsJson.nodes[0]
    }
  } else {
    post = data.allDoctorsJson.nodes[0]
  }
  // const doctor = useGoogleReviewsData().locationNodes[0];

  var backUrl = "/" + data.backButton.nodes[0].title

  var ctaClasses = classNames("column", {
    "is-2": post.hasImages,
    "is-4": !post.hasImages
  })

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="doctor-page joshua-tree-content">
          <TopSection
            language={language}
            post={post}
            backUrl={backUrl}
            // doctor={doctor}
          />
          <Body post={post} />

          {/* Learn More CTA Section */}
          <div
            className="body-section doctor-cta"
            style={{ paddingTop: !post.hasImages ? "0" : "" }}>
            <div className="columns has-text-centered">
              <div className="column">
                <h3 style={{ marginBottom: "1.125rem" }}>
                  {post.learnMore.heading}
                </h3>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className={ctaClasses}></div>
              <div className="column mobile-col">
                <p>{post.learnMore.blurb}</p>
              </div>
              <div className={ctaClasses}></div>
            </div>
            <div className="columns">
              <div className="column">
                <ButtonGroupMap isCentered buttons={post.learnMore.buttons} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query doctorPages($title: String!) {
    backButton: allUniquePagesJson(
      filter: { template: { eq: "our-oral-surgery-practice" } }
    ) {
      nodes {
        title
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        hasImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        hobbiesInterests {
          body
          imageId
        }
        certifications {
          body
          imageId
        }
        learnMore {
          heading
          blurb
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          schemaPage: PropTypes.string,
          hasImages: PropTypes.bool
          // siteMetadata: PropTypes.shape({
          //   title: PropTypes.string.isRequired,
          // }).isRequired,
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
