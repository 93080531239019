import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Button from "@components/Button/Button"

import ABOMS from "../../images/logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import Stars from "../../components/SocialReviews/Stars"

function TopSection(props) {
  if (props.post.hasImages) {
    return (
      <div className="columns is-desktop top-section doctor">
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
          }
        />

        <div className="column is-2"></div>
        <div className="column doctor-image-wrapper is-11">
          <div className="cls__single-doctor">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.cutoutImage}
              width="auto"></ImageMeta>
          </div>
          {props.post.badge && props.post.badge.hasBadge && (
            <img className="badge" src={ABOMS} />
          )}
        </div>
        <div className="column is-1"></div>

        <div className="column doctor-top-intro is-9">
          <h1 className="has-text-left">{props.post.heading}</h1>
          <p className="large-doctor-text large">{props.post.topBlurb}</p>
          <div className="rating-review">
            <div className="social-reviews">
              <SocialReviews
                useFirstLocation
                google
                isCentered
                language={props.language}
              />
            </div>
          </div>
        </div>
        <div className="column"></div>
      </div>
    )
  }
  return (
    <>
      <div
        className="columns has-text-centered top-section doctor is-vcentered"
        style={{ backgroundColor: "#fff" }}>
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
          }
        />

        <div className="column is-5"></div>
        <div className="column">
          <h1>{props.post.heading}</h1>
          <p className="large-doctor-text large">{props.post.topBlurb}</p>
        </div>
        <div className="column is-5"></div>
      </div>
      {/* <div className="columns" style={{ marginTop: "44px" }}>
        <div className="column is-offset-2 is-20">
          <div className="hl"></div>
        </div>
      </div> */}
    </>
  )
}

export default TopSection
