import PropTypes from "prop-types"
import React from "react"

import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"

export default function ButtonGroupMap(props) {
  return (
    <ButtonGroup {...props}>
      {props.buttons.map(buttonObj => {
        let button = buttonObj.button
        return (
          <Button
            contained={button.appearance === "contained"}
            minimal={button.appearance === "minimal"}
            external={button.destination === "external"}
            key={button.href}
            buttonText={button.buttonText}
            href={button.href}
          />
        )
      })}
    </ButtonGroup>
  )
}

ButtonGroupMap.propTypes = {
  buttons: PropTypes.array
}
