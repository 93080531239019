import React from "react"
import Button from "@components/Button/Button"
import "../../components/animations/scroll.scss"

import fbRecommendations from "../../images/fb-recommendations.svg"
import Stars from "../../components/SocialReviews/Stars"

import { useGoogleReviewsData } from "../../hooks/use-google-reviews-data"
import { useFacebookReviewsData } from "../../hooks/use-facebook-reviews-data"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function Iconless(props) {
  const { locationNodes } = useGoogleReviewsData()
  const facebookNodes = useFacebookReviewsData().locationNodes
  return (
    <>
      <div
        className="rating-reviews home-reviews columns has-text-centered iconless inline-stars"
        style={{ paddingTop: "40px" }}>
        {locationNodes.map(locationNode => {
          let location = locationNode.data

          if (location.totalReviews > 0) {
            return (
              <div key={location.url} className="column social-column">
                <div className="featured-social">
                  <a
                    aria-label="open link in new  browser tab"
                    className="link-wrapper"
                    href={location.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <h3>Google</h3>
                    <div className="review-icons">
                      <Stars rating={location.rating} />
                    </div>
                  </a>
                  <a
                    aria-label="open link in new  browser tab"
                    className="link-wrapper"
                    href={location.url}
                    target="_blank"
                    rel="noopener noreferrer"></a>
                </div>
                <a
                  aria-label="open link in new  browser tab"
                  href={location.url}
                  className="reviews-link"
                  target="_blank">
                  <span className="reviews-number">
                    {location.totalReviews}
                  </span>{" "}
                  <span className="reviews-word">
                    {props.language === "es" ? "Reseñas" : "Reviews"}
                  </span>
                </a>
              </div>
            )
          }
        })}
        {facebookNodes.map(locationNode => {
          if (locationNode.data.totalReviews > 0) {
            return (
              <div key={locationNode.data.url} className="column social-column">
                <div className="featured-social">
                  <a
                    aria-label="open link in new  browser tab"
                    className="link-wrapper"
                    href={locationNode.data.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <h3>Facebook</h3>
                    <div className="review-icons">
                      <Stars rating={locationNode.data.rating} />
                    </div>
                  </a>
                  <a
                    aria-label="open link in new  browser tab"
                    className="link-wrapper"
                    href={locationNode.data.url}
                    target="_blank"
                    rel="noopener noreferrer"></a>
                </div>
                <a
                  aria-label="open link in new  browser tab"
                  href={locationNode.data.url}
                  className="reviews-link"
                  target="_blank">
                  <span className="reviews-number">
                    {locationNode.data.totalReviews}
                  </span>{" "}
                  <span className="reviews-word">
                    {props.language === "es" ? "Reseñas" : "Recommendations"}
                  </span>
                </a>
              </div>
            )
          }
        })}
      </div>

      <div className="columns has-text-centered">
        <div className="column">
          <Button
            buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
            href={props.post.homeRatingsReviews.buttonOne.href}
          />
        </div>
      </div>
    </>
  )
  return (
    <>
      <div
        className="rating-reviews home-reviews columns has-text-centered iconless"
        style={{ paddingTop: "40px" }}>
        <div className="column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer">
              <h3>Google</h3>
            </a>
            <a
              className="link-wrapper"
              href={props.google.url}
              target="_blank"
              rel="noopener noreferrer"></a>
            <a href={props.google.url} className="reviews-link" target="_blank">
              {props.google.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
            <div className="review-icons">
              <h4 className="google-rating">
                {props.google.rating.toFixed(1)}
              </h4>
              <Stars rating={props.google.rating} />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="featured-social">
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer">
              <h3>Facebook</h3>
            </a>
            <a
              className="link-wrapper"
              href={props.facebook.url}
              target="_blank"
              rel="noopener noreferrer"></a>
            <a
              href={props.facebook.url}
              className="reviews-link"
              target="_blank">
              {props.facebook.totalReviews}{" "}
              {props.language === "es" ? "Reseñas" : "Reviews"}
            </a>
          </div>
        </div>
      </div>

      <div className="columns has-text-centered">
        <div className="column">
          <Button
            buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
            href={props.post.homeRatingsReviews.buttonOne.href}
          />
        </div>
      </div>
    </>
  )
}

function WithIcons(props) {
  return (
    <>
      <div className="rating-reviews home-reviews">
        <div className="featured-social">
          <a
            aria-label="open link in new  browser tab"
            className="link-wrapper"
            href={props.google.url}
            target="_blank"
            rel="noopener noreferrer">
            <h3>Google</h3>
          </a>
          <a
            aria-label="open link in new  browser tab"
            className="link-wrapper"
            href={props.google.url}
            target="_blank"
            rel="noopener noreferrer">
            <div className="review-icons">
              <h4 className="google-rating">
                {props.google.rating.toFixed(1)}
              </h4>
              <Stars rating={props.google.rating} />
            </div>
          </a>
          <a
            aria-label="open link in new  browser tab"
            href={props.google.url}
            target="_blank">
            {props.google.totalReviews}{" "}
            {props.language === "es" ? "Reseñas" : "Reviews"}
          </a>
        </div>
        <div className="featured-social">
          <a
            aria-label="open link in new  browser tab"
            className="link-wrapper"
            href={props.facebook.url}
            target="_blank"
            rel="noopener noreferrer">
            <h3>Facebook</h3>
          </a>
          <a
            aria-label="open link in new  browser tab"
            className="link-wrapper"
            href={props.facebook.url}
            target="_blank"
            rel="noopener noreferrer">
            <div className="review-icons">
              <div className="svg-wrapper">
                <img src={fbRecommendations} alt="Facebook Recommendations" />
              </div>
            </div>
          </a>
          <a
            aria-label="open link in new  browser tab"
            href={props.facebook.url}
            target="_blank">
            {props.facebook.totalReviews}{" "}
            {props.language === "es" ? "Reseñas" : "Reviews"}
          </a>
        </div>
      </div>

      {!props.noButton && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button
              buttonText={props.post.homeRatingsReviews.buttonOne.buttonText}
              href={props.post.homeRatingsReviews.buttonOne.href}
            />
          </div>
        </div>
      )}
    </>
  )
}

function RatingsAndReviews(props) {
  if (props.topDown) {
    return <TopDown {...props} />
  }
  return <LeftRight {...props} />
}

function TopDown(props) {
  return (
    <section
      className="home-section ratings-review top-down color-back"
      style={{ paddingBottom: "60px", paddingTop: "60px" }}>
      <div className="container" data-aos="fade-up" data-aos-duration="1200">
        <div className="columns has-text-centered">
          <div className="column"></div>
          <div className="column is-18">
            {props.iconless && <h2>{props.post.homeRatingsReviews.heading}</h2>}
            {!props.iconless && (
              <h3>{props.post.homeRatingsReviews.heading}</h3>
            )}
            <div
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(props.post.homeRatingsReviews.text)
              )}></div>
          </div>
          <div className="column"></div>
        </div>
        <div className="columns">
          <div className="column"></div>
          <div className="column is-20-tablet is-14-desktop">
            {!props.iconless && (
              <WithIcons
                post={props.post}
                google={props.google}
                facebook={props.facebook}
              />
            )}
            {props.iconless && (
              <Iconless
                post={props.post}
                google={props.google}
                facebook={props.facebook}
              />
            )}
          </div>
          <div className="column"></div>
        </div>
      </div>
    </section>
  )
}

function LeftRight(props) {
  return (
    <section
      className="home-section ratings-review left-right color-back"
      style={{ paddingBottom: "60px", paddingTop: "60px" }}
      data-aos="fade-up"
      data-aos-duration="1200">
      <div className="columns is-desktop">
        <div className="column is-2"></div>
        <div className="column is-10">
          {props.iconless && <h2>{props.post.homeRatingsReviews.heading}</h2>}
          {!props.iconless && <h3>{props.post.homeRatingsReviews.heading}</h3>}
          <div
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.homeRatingsReviews.text)
            )}></div>
        </div>
        <div className="column is-2"></div>
        <div className="column is-8">
          {!props.iconless && (
            <WithIcons
              noButton
              post={props.post}
              google={props.google}
              facebook={props.facebook}
            />
          )}
          {props.iconless && (
            <Iconless
              noButton
              post={props.post}
              google={props.google}
              facebook={props.facebook}
            />
          )}
        </div>
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

export default RatingsAndReviews
