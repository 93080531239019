exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-open-for-care-js": () => import("./../../../src/pages/open-for-care.js" /* webpackChunkName: "component---src-pages-open-for-care-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-covid-19-js": () => import("./../../../src/templates/covid-19.js" /* webpackChunkName: "component---src-templates-covid-19-js" */),
  "component---src-templates-get-started-js": () => import("./../../../src/templates/get-started.js" /* webpackChunkName: "component---src-templates-get-started-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-our-oral-surgery-practice-js": () => import("./../../../src/templates/our-oral-surgery-practice.js" /* webpackChunkName: "component---src-templates-our-oral-surgery-practice-js" */),
  "component---src-templates-patient-stories-js": () => import("./../../../src/templates/patient-stories.js" /* webpackChunkName: "component---src-templates-patient-stories-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-procedures-js": () => import("./../../../src/templates/procedures.js" /* webpackChunkName: "component---src-templates-procedures-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/singleDoctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-instruction-js": () => import("./../../../src/templates/singleInstruction.js" /* webpackChunkName: "component---src-templates-single-instruction-js" */),
  "component---src-templates-single-procedure-js": () => import("./../../../src/templates/singleProcedure.js" /* webpackChunkName: "component---src-templates-single-procedure-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/singleReview.js" /* webpackChunkName: "component---src-templates-single-review-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-three-d-imaging-js": () => import("./../../../src/templates/three-d-imaging.js" /* webpackChunkName: "component---src-templates-three-d-imaging-js" */),
  "component---src-templates-your-first-visit-js": () => import("./../../../src/templates/your-first-visit.js" /* webpackChunkName: "component---src-templates-your-first-visit-js" */)
}

